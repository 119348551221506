import { Injectable } from '@angular/core';
import { IScheduleTask } from 'app/shared/model/schedule-task.model';
import { IMaterial } from 'app/shared/model/material.model';
import { lastValueFrom } from 'rxjs';
import { TaskService } from 'app/shared/dataservices/task.service';
import { HttpResponse } from '@angular/common/http';
import { IProject } from 'app/shared/model/project.model';

@Injectable({ providedIn: 'root' })
export class LoadMaterialsService {
    constructor(private taskApi: TaskService) {
    }

    async load(project: IProject, scheduleTask: IScheduleTask): Promise<IScheduleTask> {
        if (!scheduleTask?.materialCategories?.length) {
            return Promise.resolve(scheduleTask);
        }

        return lastValueFrom(this.taskApi.materials(project.id, scheduleTask.taskId)).then(
            (res: HttpResponse<IMaterial[]>) => {
                scheduleTask._materials = res.body;
                return scheduleTask;
            });
    }
}
